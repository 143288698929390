.presale-title {
    height: 4%;
    min-height: 70px;
    width: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.presale-title h1 {
    font-size: 1.7em;
    color: white;
    font-family: 'Bungee Inline', cursive;
}

.presale {
    border-radius: 1rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
    height: 14%;
    width: 76%;
    background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
    display: flex;
    flex-direction: column;
    padding: 0em 3em 0em 3em;
    max-width: 1000px;
}

.first-pre {
    height: 20%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1vmin;
}

.logo h4 {
    font-size: 1.2em;
    color: white;
}

.logo h3 {
    font-size: 1.2em;
    background: linear-gradient(45deg, rgb(177, 179, 37), rgb(246, 172, 37), rgb(220, 220, 70));
    -webkit-background-clip: text;
    /* Apply gradient as text color for webkit-based browsers */
    color: transparent;
}

.logo #eth {
    width: 40px;
}

.left-info {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 1.3vmin;
    flex-direction: column;
    align-items: center;
}

.token {
    width: 100%;

    display: flex;
    justify-content: end;
    align-items: center;
}

.token p {
    color: white;
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.pricing {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.pricing p {
    color: white;
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.input {
    width: 100%;
    height: 25%;
    background: radial-gradient(100% 100% at 0 0, hsla(0, 5%, 7%, 0.176) 0, hsla(0, 7%, 6%, 0.054) 100%);
    border-radius: 1.2rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
    display: flex;
    position: relative;
    align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* styles.css or your desired CSS file */
input:focus {
    outline: none;
}


/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    border-radius: 1.2rem;
    color: white;
    text-align: right;

    font-size: 1.7rem;
    padding: 0em 1em 0em 0em;

}

.eth-input {
    width: 120px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vmin;
    border: 1px solid rgba(229, 231, 235, 0.11);
    border-radius: 1.1em;
    position: absolute;
    left: 20px;
    cursor: pointer;
}

.balance {
    width: 120px;
    height: 60px;
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.balance p {
    color: rgb(88, 113, 235);
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.eth-input:hover {
    background-color: rgba(0, 0, 0, 0.106);
}

.eth-input h4 {
    font-size: 1.1em;
    /* Apply gradient as text color for webkit-based browsers */
    color: white;
}

.eth-input #eth {
    width: 23px;
}

.down {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sw {
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;

    background-color: rgba(0, 0, 0, 0.072);
    border-radius: 1.2rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
}



.second-input input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    border-radius: 1.2rem;
    color: white;
    text-align: right;

    font-size: 1.7rem;
    padding: 0em 1em 0em 0em;
    cursor: default;

}


.second-input {
    width: 100%;
    height: 25%;
    background: radial-gradient(100% 100% at 0 0, hsla(0, 5%, 7%, 0.176) 0, hsla(0, 7%, 6%, 0.054) 100%);
    border-radius: 1.2rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
    display: flex;
    position: relative;
    align-items: center;
}


.allocation {
    width: 120px;
    height: 60px;
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.allocation p {
    color: rgb(171, 175, 195);
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.button {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.button button {
    font-size: 1em;
    color: white;
    background: linear-gradient(135deg, #16a085, #1abc9c, #3498db);
    border: none;
    border-radius: 1rem;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    height: 50%;
    font-weight: 500;
    animation: gradientAnimation 10s ease infinite;
    cursor: pointer;
    background-size: 200% 400%;


}

.button button:hover {
    background: linear-gradient(135deg, #16a084be, #1abc9cb6, #3498dbb1);
}

.mobileonly{
    display: none;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@media screen and (max-height:850px) {
    .presale {
        border-radius: 1rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        height: 14%;
        width: 76%;
        min-height: 600px;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
        display: flex;
        flex-direction: column;
        padding: 0em 3em 0em 3em;
        max-width: 1400px;
    }
}


@media screen and (max-width:382px) {
    .presale {
        border-radius: 1rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        height: 15%;
        width: 84%;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
        display: flex;
        flex-direction: column;
        padding: 0em 1em 0em 1em;
        max-width: 1400px;
    }

    .first-pre {
        height: 20%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo h4 {
        font-size: 0.8em;
        color: white;
    }

    .logo h3 {
        font-size: 0.8em;
        background: linear-gradient(45deg, rgb(177, 179, 37), rgb(246, 172, 37), rgb(220, 220, 70));
        -webkit-background-clip: text;
        /* Apply gradient as text color for webkit-based browsers */
        color: transparent;
    }

    .logo #eth {
        width: 24px;
    }

    .token p {
        color: white;
        font-size: 0.7em;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .pricing {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .pricing p {
        color: white;
        font-size: 0.7em;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
    }

    .input {
        width: 100%;
        height: 20%;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 5%, 7%, 0.176) 0, hsla(0, 7%, 6%, 0.054) 100%);
        border-radius: 1.2rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        display: flex;
        position: relative;
        align-items: center;
    }

    .second-input {
        width: 100%;
        height: 20%;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 5%, 7%, 0.176) 0, hsla(0, 7%, 6%, 0.054) 100%);
        border-radius: 1.2rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        display: flex;
        position: relative;
        align-items: center;
        z-index: 99;
    }
    

    .input input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        border-radius: 1.2rem;
        color: white;
        text-align: right;
        z-index: 99;

        font-size: 1.3rem;
        padding: 0em 1.4em 0em 0em;

    }


    .balance {
        width: 120px;
        height: 60px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: end;
        right: 20px;
        padding: 0em 0em 1em 0em;
    }

    .second-input input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        border-radius: 1.2rem;
        color: white;
        text-align: right;
        z-index: 99;

        font-size: 1.3rem;
        padding: 0em 1.4em 0em 0em;

    }


    .allocation {
        width: 120px;
        height: 60px;
        position: absolute;
        bottom: 0;
        right: 20px;

        display: flex;
        justify-content: center;
        align-items: end;
        padding: 0em 0em 1em 0em;
    }

    .allocation p {
        color: rgb(171, 175, 195);
        font-size: 0.9em;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }


    .button button {
        font-size: 1.2em;
        color: white;
        background: linear-gradient(135deg, #16a085, #1abc9c, #3498db);
        border: none;
        border-radius: 1rem;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        height: 50%;
        font-weight: 500;
        animation: gradientAnimation 10s ease infinite;
        cursor: pointer;
        background-size: 200% 400%;


    }
    .mobileonly{
        display: flex;
        height: 5%;
    }

}



@media screen and (max-width:432px) {
    .presale {
        border-radius: 1rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        height: 15%;
        width: 84%;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
        display: flex;
        flex-direction: column;
        padding: 0em 1em 0em 1em;
        max-width: 1400px;
    }

    .first-pre {
        height: 20%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo h4 {
        font-size: 0.8em;
        color: white;
    }

    .logo h3 {
        font-size: 0.8em;
        background: linear-gradient(45deg, rgb(177, 179, 37), rgb(246, 172, 37), rgb(220, 220, 70));
        -webkit-background-clip: text;
        /* Apply gradient as text color for webkit-based browsers */
        color: transparent;
    }

    .logo #eth {
        width: 24px;
    }

    .token p {
        color: white;
        font-size: 0.7em;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .pricing {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .pricing p {
        color: white;
        font-size: 0.7em;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
    }

    .input {
        width: 100%;
        height: 20%;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 5%, 7%, 0.176) 0, hsla(0, 7%, 6%, 0.054) 100%);
        border-radius: 1.2rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        display: flex;
        position: relative;
        align-items: center;
    }

    .second-input {
        width: 100%;
        height: 20%;
        background: radial-gradient(100% 100% at 0 0, hsla(0, 5%, 7%, 0.176) 0, hsla(0, 7%, 6%, 0.054) 100%);
        border-radius: 1.2rem;
        --tw-backdrop-blur: blur(16px);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
        display: flex;
        position: relative;
        align-items: center;
        z-index: 99;
    }
    

    .input input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        border-radius: 1.2rem;
        color: white;
        text-align: right;
        z-index: 99;

        font-size: 1.3rem;
        padding: 0em 1.4em 0em 0em;

    }


    .balance {
        width: 120px;
        height: 60px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: end;
        right: 20px;
        padding: 0em 0em 1em 0em;
    }

    .second-input input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        border-radius: 1.2rem;
        color: white;
        text-align: right;
        z-index: 99;

        font-size: 1.3rem;
        padding: 0em 1.4em 0em 0em;

    }


    .allocation {
        width: 120px;
        height: 60px;
        position: absolute;
        bottom: 0;
        right: 20px;

        display: flex;
        justify-content: center;
        align-items: end;
        padding: 0em 0em 1em 0em;
    }

    .allocation p {
        color: rgb(171, 175, 195);
        font-size: 0.9em;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }


    .button button {
        font-size: 1.2em;
        color: white;
        background: linear-gradient(135deg, #16a085, #1abc9c, #3498db);
        border: none;
        border-radius: 1rem;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        height: 50%;
        font-weight: 500;
        animation: gradientAnimation 10s ease infinite;
        cursor: pointer;
        background-size: 200% 400%;


    }
    .mobileonly{
        display: flex;
        height: 5%;
    }

}