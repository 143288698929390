@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url(https://fonts.googleapis.com/css?family=Playfair+Display);

@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');

@font-face {
  font-family: 'Rocher';
  src: url(https://assets.codepen.io/9632/RocherColorGX.woff2);
}


* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style: none;
}

:root {
  --c-1: #1C371C;
  --c-2: #1C371C;

  --tw-gradient-from: #b45bff var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(180, 91, 255, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #ffd93d var(--tw-gradient-to-position);

}


body {

  background: linear-gradient(270deg, #1B0A37, #1B0A37);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 450vh;
  position: relative;
  width: 100%;
}



.wrap {
  display: flex;
  position: absolute;
  flex-direction: column;

  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  justify-content: center;
}


header {
  width: 95%;
  max-width: 1900px;
  height: 125px;
  display: flex;
  z-index: 120;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background: transparent;
  border: non;
}



.logo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 90%;
  transform: translateY(-20px);
  /* Start position off the screen on the top */
  animation: fadeInFromTop 1.2s forwards;
}

.logo-img img {
  width: 90%;
}

header .pages {

  display: flex;
  width: 40%;
  height: 90%;
  flex-direction: row;
  align-items: center;
  color: white;
  justify-content: center;
  gap: 1.8vmin;
}

header .pages a {
  font-family: 'Poppins', sans-serif;
  font-size: clamp(0.6rem, 0.4rem + 1vw, 1.25rem);
  cursor: pointer;
  padding: 0.7em 0.6em;
  border-radius: 9px;
  transform: translateY(-20px);
  /* Start position off the screen on the top */
  animation: fadeInFromTop 1.2s forwards;
}

@keyframes fadeInFromTop {
  to {
    opacity: 1;
    transform: translateY(0);
    /* Move the links to their original position */
  }
}


header .pages a:hover {
  background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.069) 0, hsla(0, 0%, 100%, 0.045) 100%);
}

.cb {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 90%;

}

.cb button {
  width: 90%;
  color: rgba(255, 255, 255, 0.847);
  display: flex;
  height: 45%;
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
  border: 1px solid rgba(218, 208, 208, 0.427);
  background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.023) 0, hsla(0, 0%, 100%, .01) 100%);
  --tw-border-opacity: 0.2;
  color: rgb(255 255 255/var(--tw-text-opacity));
  --tw-text-opacity: 1;
  align-items: center;
  border-radius: 0.375rem;
  font-weight: 700;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
  justify-content: center;
  align-items: center;
  gap: 0.5vmin;
  cursor: pointer;
  transform: translateY(-20px);
  /* Start position off the screen on the top */
  animation: fadeInFromTop 1.2s forwards;
  position: relative;
  overflow: hidden;
}

.cb button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(218, 208, 208, 0.226);
  transition: left 0.3s ease-out;
  z-index: -1;
}

.cb button:hover::before {
  left: 0;
}

.cb button h5 {
  display: inline-block;
  font-size: clamp(0.9rem, 0.6rem + 1vw, 0.8rem);
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.cb button img {
  display: inline-block;
  width: 20px;
  height: 10px;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.2em;
}

.cb button img {
  width: 500px;
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
}

.glow {
  position: relative;
  width: 780w;
  height: 40vw;
  min-height: 300px;
  max-height: 300px;
  max-width: 1300px;
  text-align: center;
  flex-direction: column;
  color: #f7f8f8;
  display: flex;
  justify-content: center;
  gap: 4vmin;
  align-items: center;
  z-index: 99;

}

.glow h2 {
  font-weight: 600;
  width: auto;
  font-size: clamp(2rem, 3.2rem + 1vw, 2.8rem);
}

.glow::after {

  position: absolute;
  content: "";
  top: 5vw;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(10w);
  filter: blur(10vw);
  background: linear-gradient(270deg, #0f1bff3b, #7f0fff3e);
  background-size: 1000% 1000%;
  animation: animateGlow 12s ease infinite;

}


main {
  width: 100%;
  overflow: hidden;

  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.blank {
  display: flex;
  height: 3%;
  min-height: 80px;
}

.paragraph {
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 1.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
    /* Optional: Slide in from the bottom */
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.glow p {
  color: hsla(0, 0%, 100%, .7);
  white-space: pre-line;
  font-size: clamp(1.1rem, 0.6rem + 1vw, 1.6rem);
  line-height: 2;
  justify-content: center;
  width: 70%;
}

.cat_robot {
  display: flex;
  height: 29%;
  max-height: 300px;
  width: 100%;
  justify-content: center;
  align-items: end;
}

.cat_robot img {
  width: 200px;

  position: relative;
  animation: floating 3s ease-in-out infinite;
}

.loop {
  position: absolute;
  right: -80px;
  top: 30%;
}

.loop img {
  width: 175px;
  filter: blur(0.2px);
  animation: tiltAnimation 2s ease-in-out infinite;
}




.s {
  position: absolute;
  right: 240px;
  top: 50%;
  transform: translateY(-50%);
}

.s img {
  width: 75px;
  filter: blur(0.2px);
}







.title {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 50%;
  flex-direction: column;
  opacity: 0;
  /* Start with opacity set to 0, so the element is initially hidden */
  transform: translateX(-20px);
  /* Start position off the screen on the left */
  animation: fadeInFromLeft 1s forwards;
}

.title h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.title span {
  font-weight: 600;
  width: 230px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: clamp(2rem, 3.2rem + 1vw, 2.8rem);
  opacity: 0;
  /* Start with opacity set to 0, so the element is initially hidden */
  transform: translateX(-60px);
  /* Start position off the screen on the left */
  animation: fadeInFromLeft 2s forwards;
}

@keyframes fadeInFromLeft {
  to {
    opacity: 1;
    transform: translateX(0);
    /* Move the element to its original position */
  }
}


.below {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* BUTTON DOCS */

.docs {
  display: flex;
  width: 95%;
  height: 5%;
  z-index: 99;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 300px;
}




.docs button {

  opacity: 0;
  transform: translateX(-50px);
  /* Start position off the screen on the left */
  animation: fadeInFromLeft 1s forwards;
  width: 15%;
  min-width: 220px;
  min-height: 50px;
  color: rgba(255, 255, 255, 0.847);
  display: flex;
  height: 45%;
  box-shadow: inset -8px -8px 250px hsla(79, 49%, 57%, 0.02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
  border: 1px solid rgba(218, 208, 208, 0.427);
  background: transparent;
  --tw-border-opacity: 0.2;
  color: rgb(255 255 255/var(--tw-text-opacity));
  --tw-text-opacity: 1;
  align-items: center;
  border-radius: 0.975rem;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
  justify-content: center;
  align-items: center;
  gap: 0.5vmin;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.docs button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(218, 208, 208, 0.226);
  transition: left 0.3s ease-out;
  z-index: -1;
}

.docs button:hover::before {
  left: 0;
}












/* KEY FRAMES */

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes textColor {
  0% {
    color: #7e0fff;
  }

  50% {
    color: #0fffc1;
  }

  100% {
    color: #7e0fff;
  }
}


@keyframes fadeAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}





.how-it-works {
  width: 85%;
  height: 11%;
  min-height: 140px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1.7vmin;
  align-items: center;
  color: white;
}

.how-it-works h2 {
  font-size: clamp(2.4rem, 3.2rem + 1vw, 3.5rem);
}

.how-it-works h2 span {
  background: linear-gradient(45deg, rgb(214, 214, 24), #c9b76f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.working {
  display: flex;
  max-width: 1100px;
  flex-direction: column;
  width: 70%;
  height: 20%;
  justify-content: center;
  gap: 2vmin;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.working::after {
  position: absolute;
  content: "";
  top: 1vw;
  left: 0;
  right: 1vw;
  z-index: 1;
  height: 100%;
  width: 10000%;
  margin: 0 auto;
  transform: scale(10w);
  filter: blur(10vw);
  background: linear-gradient(270deg, #0f1bff30, #6812cb57);
  background-size: 1000% 1000%;
  animation: animateGlow 12s ease infinite;
}





@keyframes tiltAnimation {

  0%,
  100% {
    transform: translateX(0) rotate(0);
  }

  25% {
    transform: translateX(-10%) rotate(-5deg);
  }

  75% {
    transform: translateX(10%) rotate(5deg);
  }
}

.first-row {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 23px;

  border-radius: 1rem;
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
  background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);

}

.second-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.step {
  position: relative;
  color: white;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  font-size: 1.7rem;
  display: flex;
  height: 4rem;
  width: 4rem;
  align-items: center;
  justify-content: center;
  background: radial-gradient(100% 100% at 0 0, hsla(178, 31%, 55%, 0.449) 0, hsla(179, 25%, 38%, 0.481) 100%);
  border: 4px solid #ffffff73;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40%;
}

.top-card {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
}

.first-row h3 {
  color: white;
  font-size: clamp(1.2rem, 3.2rem + 1vw, 1.5rem);
}

.first-row p {
  color: rgba(255, 255, 255, 0.695);
}

.text p span {

  color: rgb(214, 214, 24);
  font-weight: 600;
}

.text-bubble p span {

  color: rgb(214, 214, 24);
  font-weight: 600;
}


.bubble-1 {
  height: 100%;
  width: 48%;
  border-radius: 1rem;
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
  background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
  --tw-border-opacity: 0.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-bubble {
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: center;
  align-items: center;
}


.second-row h3 {
  color: white;
  font-size: clamp(1.2rem, 3.2rem + 1vw, 1.5rem);
}

.second-row p {
  color: rgba(255, 255, 255, 0.695);
}

.text-bubble {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 30%;
}

p {
  text-align: center;
}



























.scroll {
  width: 100%;
  display: flex;
  height: 7%;
  max-height: 110px;
  overflow: hidden;
}

.scroll div {
  color: #e7d323;
  font-size: 2em;
  white-space: nowrap;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  animation: animate 100s linear infinite;
  width: fit-content;
  gap: 4vmin;
  transform: translateX(100%);

}

.scroll div:nth-child(2) {

  animation: animate2 20s linear infinite;

}

.scroll div span {
  -webkit-text-stroke: 2px #ffffff;
  color: transparent;
  font-family: 'Poppins', sans-serif;
}

@keyframes animate {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-30%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.scroll.text {

  transform: rotate(0deg) translateY(20px) translateX(-20px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}



.bands {

  width: 100%;
  display: flex;
  position: relative;
  height: 5%;

}



.res {

  position: absolute;
  width: 130%;
  height: 20px;
  transform: rotate(4deg) translateY(50px) translateX(0px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  white-space: nowrap;
  background: linear-gradient(to bottom right, #90dbe6, #82c19e, #82c19e);
  background: -webkit-linear-gradient(to bottom right, #90dbe6, #82c19e, #82c19e);
  background: -moz-linear-gradient(to bottom right, #90dbe6, #82c19e, #82c19e);
  background: -o-linear-gradient(to bottom right, #90dbe6, #82c19e, #82c19e);
  background: linear-gradient(to bottom right, #90dbe6, #82c19e, #82c19e);
  font-weight: 900;
  opacity: 0.8;
}

.ser {

  position: absolute;
  width: 130%;
  height: 20px;
  transform: rotate(-4deg) translateY(50px) translateX(0px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  background: linear-gradient(to bottom right, #ffffff, #e8e2e2);
  white-space: nowrap;
  font-weight: 900;
  opacity: 0.8;
}







.foug {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  bottom: 0;
}

.bubbles {
  position: relative;
  display: flex;
}

.bubbles span {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 50%;
  box-shadow: 0 0 0 10pxx #4fc3dc44,
    0 0 50px #4fc3dc,
    0 0 100px #4fc3dc;
  animation: animation 15s linear infinite;
  backdrop-filter: var(--tw-backdrop-blur);
  animation-duration: calc(120s / var(--i));
}

.bubbles img {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 50%;
  /* box-shadow: 0 0 0 10pxx #4fc3dc44,
  0 0 50px #4fc3dc,
  0 0 100px #4fc3dc; */
  animation: animation 15s linear infinite;
  backdrop-filter: var(--tw-backdrop-blur);
  animation-duration: calc(120s / var(--i));
}

.bubbles span:nth-child(even) {
  /* box-shadow: 0 0 0 10pxx #ff2d7544,
  0 0 50px #4fc3dc,
  0 0 100px #4fc3dc;
  background: #ff2d7648; */

}

@keyframes animation {
  0% {
    transform: translateY(100vh) scale(0);
  }

  100% {
    transform: translateY(-10vh) scale(1);
  }
}

.blank-2 {
  display: flex;
  height: 3%;
}

.tokenomics {
  border-radius: 1rem;
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
  height: 20%;
  width: 80%;
  background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
  display: flex;
  flex-direction: row;
  max-width: 1400px;
}

.right {
  border-radius: 1rem;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right .right-area {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 80%;
  height: 100%;
}



.right .right-area .subtitle {
  display: flex;
  align-items: end;
  width: 100%;
  height: 10%;
}

.right .right-area .subtitle h4 {
  background: linear-gradient(45deg, rgb(177, 179, 37), rgb(246, 172, 37), rgb(220, 220, 70));
  -webkit-background-clip: text;
  /* Apply gradient as text color for webkit-based browsers */
  color: transparent;
  font-size: 1.2em;
}

.right .right-area .token-name {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 13%;
}

.right .right-area .token-name h2 {
  font-size: 1.875rem;
  color: whitesmoke;
  font-family: 'Bungee Inline', cursive;
  font-weight: 900;

}

.right .right-area .supply {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  color: white;
  height: 5%;
  gap: 1vmin;
}


.right .right-area .supply h5 {
  font-size: 1.1em;

}

.supply h5:nth-child(2) {
  font-weight: 900;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.checks {
  display: flex;
  width: 100%;
  height: 35%;
  flex-direction: column;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.135);
}

.double-checks {
  display: flex;
  width: 100%;
  height: 30%;
  flex-direction: column;
}


.first-check {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
}

.double-check {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
}


.white-icon {
  color: hsla(0, 0%, 100%, 0.531);
}

.zonecheck {
  width: 3%;
  min-width: 30px;
  display: flex;
  height: 100%;
  justify-content: start;
  align-items: center;
}

.zonecheck img {
  color: white;
}

.zonetext {
  display: flex;
  height: 100%;
  align-items: center;
}

.double-check .zonetext p {
  text-align: start;
}

/* styles.css */
.bold-white {
  color: white;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

.semi-transparent-white {
  color: hsla(0, 0%, 100%, 0.641);
  font-family: 'Poppins', sans-serif;
}

.check-transparent-white {
  color: hsla(0, 0%, 100%, 0.641);
  font-family: 'Poppins', sans-serif;
}


.left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
}

.left .left-area {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 90%;
  height: 100%;
}



.left .subtitle {
  display: flex;
  align-items: end;
  width: 100%;
  height: 10%;
  margin-bottom: 20px;
}

.left .subtitle h4 {
  color: hsla(0, 0%, 100%, 0.641);
  -webkit-background-clip: text;
  /* Apply gradient as text color for webkit-based browsers */
  font-size: 1em;
}

.distribution {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 17%;
}

.distribution .number {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
}


.distribution .number h4 {
  font-size: 1.15em;
  color: white;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

.distribution .members {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}

.distribution .members p {
  color: hsla(0, 0%, 100%, .7);
  font-size: 1em;
}

.distribution .loader-wrap {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
}

.distribution .loader-wrap .loader {
  height: 4px;
  width: 80%;

  border: none;
  border-radius: 1em;
  background-color: hsla(0, 0%, 100%, .7);
}

.distribution .loader-wrap .loader .state {
  width: 5%;
  height: 100%;
  border: none;
  border-radius: 1em;
  background-color: hsla(219, 88%, 49%, 0.7);
}

.distribution .loader-wrap .loader .cex {
  width: 5%;
  height: 100%;
  border: none;
  border-radius: 1em;
}

.distribution .loader-wrap .loader .cex {
  width: 5%;
  height: 100%;
  border: none;
  border-radius: 1em;
}

.distribution .loader-wrap .loader .liquidity {
  width: 50%;
  height: 100%;
  border: none;
  border-radius: 1em;
  background-color: hsla(94, 88%, 49%, 0.7);
}

.distribution .loader-wrap .loader .seed {
  width: 40%;
  height: 100%;
  border: none;
  border-radius: 1em;
  background-color: hsla(345, 88%, 49%, 0.7);
}


.distribution .secours {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
}


.distribution .secours h4 {
  font-size: 1.15em;
  color: white;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}


.left .vesting {
  display: flex;
  align-items: end;
  width: 100%;
  height: 4%;
  margin-bottom: 20px;
}

.left .vesting h4 {
  color: hsla(0, 0%, 100%, 0.641);
  -webkit-background-clip: text;
  /* Apply gradient as text color for webkit-based browsers */
  font-size: 0.8em;
}

.vesting-wrap {
  display: flex;
  height: 4%;
  width: 100%;
  gap: 1vmin;
  align-items: center;
}

.vesting-loader {

  width: 14px;
  height: 2px;
  border: none;
  border-radius: 109px;
  background: linear-gradient(to right, rgb(190, 190, 32), rgb(215, 215, 34));


}

.small-white {
  color: white;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
}

.small-transparent-white {
  color: hsla(0, 0%, 100%, 0.641);
  font-size: 0.8em;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.first-check .zonetext p {
  text-align: start;
}

.FAQ {

  display: flex;
  margin-top: 40px;
  flex-direction: column;
  height: 3%;
  width: 80%;
  justify-content: center;
  align-items: center;
  gap: 1vmin;

}

.FAQ h2 {
  background: linear-gradient(45deg, rgb(177, 179, 37), rgb(235, 199, 134), rgb(220, 220, 70));
  -webkit-background-clip: text;
  /* Apply gradient as text color for webkit-based browsers */
  color: transparent;
  font-size: 2em;
}

.FAQ p {

  background: linear-gradient(45deg, rgb(219, 190, 219), rgb(171, 163, 149), rgb(207, 207, 185));
  -webkit-background-clip: text;
  /* Apply gradient as text color for webkit-based browsers */
  color: transparent;
  font-size: 1.2em;

}

.fak {

  max-width: 1400px;
  min-width: 350px;
  width: 80%;
  margin-top: 2rem;
  padding-bottom: 1rem;
  min-height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* 
  padding: 1.4rem 1.5rem 1rem 1.5rem;
  box-sizing: border-box;
  border: 1px solid rgba(229, 231, 235, 0.255);
  border-radius: 1.1em; */

  padding: 1.4rem 0rem 1rem 0rem;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(229, 231, 235, 0.087);
}



.fak.active .answer {
  max-height: 800px;
}

.question {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question h3 {
  color: white;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: start;
  font-weight: 600;

}

.answer {
  max-height: 0;
  overflow: hidden;
  width: 100%;
  transition: max-height 0.5s ease-in-out;
  /* Add the transition property */
}

.answer p {
  padding: 1em 0em 1em 0em;
  width: 100%;
  line-height: 1.6;
  align-items: start;
  color: hsla(0, 0%, 100%, 0.641);
  font-family: 'Poppins', sans-serif;
}

.fak .answer p {
  text-align: start;
}

@media screen and (max-width: 1053px) {
  .answer p {
    padding: 2em 0em 3em 0em;
    align-items: start;
  }

}

@media screen and (max-width: 883px) {
  .answer p {
    padding: 2em 0em 4em 0em;
    align-items: start;
  }

}

@media screen and (max-width: 653px) {
  .answer p {
    padding: 2em 0em 5em 0em;
    font-size: 0.9em;
  }

}


@media screen and (max-width: 583px) {
  .answer p {
    padding: 2em 0em 6em 0em;
    font-size: 0.9em;
  }

}


.arrow {
  /* Set the initial rotation to 0 degrees */
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  /* Add the transition property */
}

.arrow.rotate {
  /* Set the final rotation to 180 degrees when active */
  transform: rotate(180deg);
  /* Add any other styles you need for the rotated SVG, e.g., changing colors or size */
}


.socials {
  width: 80%;
  display: flex;
  flex-direction: column;
  height: 5%;
  color: white;
  justify-content: center;
  align-items: center;

}

.socials-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  gap: 1vmin;
}

.my-icon {
  font-size: 30px;
  /* Adjust the font-size to control the icon size */
  cursor: pointer;
  z-index: 99;
  transition: font-size 0.3s ease;
  /* Smooth transition for size change */

}

.my-icon:hover {
  font-size: 34px;
  color: rgba(255, 255, 255, 0.716);
  animation: shiningEffect 1s infinite;
  /* Shining effect animation */

}

@keyframes shiningEffect {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

/* Example: Another custom style for specific icons */
.twitter-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
  z-index: 99;
  transition: font-size 0.3s ease;
  /* Smooth transition for size change */
}

.twitter-icon:hover {
  font-size: 38px;
  color: rgba(255, 255, 255, 0.716);
  animation: shiningEffect 1s infinite;
  /* Shining effect animation */
}


@media screen and (max-width: 653px) {
  .my-icon {
    font-size: 20px;
    /* Adjust the font-size to control the icon size */
    cursor: pointer;
    z-index: 99;
    transition: font-size 0.3s ease;
    /* Smooth transition for size change */

  }

  .my-icon:hover {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.716);
    animation: shiningEffect 1s infinite;
    /* Shining effect animation */

  }

  .twitter-icon {
    color: #ffffff;
    font-size: 22px;
    cursor: pointer;
    z-index: 99;
    transition: font-size 0.3s ease;
    /* Smooth transition for size change */
  }

  .twitter-icon:hover {
    font-size: 25px;
    color: rgba(255, 255, 255, 0.716);
    animation: shiningEffect 1s infinite;
    /* Shining effect animation */
  }


}

.how-it-works .code-background {
  background-color: #070000;
  background-image: linear-gradient(#222 1px, transparent 1px),
    linear-gradient(to right, #222 1px, #1a1a1a 1px);
  background-size: 40px 40px;
  padding: 0.5em 0.6em 0.5em 0.6em;
  color: #82c19e;
  border-radius: 11px;
  z-index: 99;
}







/* Sizing Tokenomics*/


@media screen and (max-width:1050px) {

  .right .right-area .supply {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    color: white;
    height: 5%;
    gap: 1vmin;
  }


  .right .right-area .supply h5 {
    font-size: 1em;

  }

  .supply h5:nth-child(2) {
    font-weight: 900;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .tokenomics {
    border-radius: 1rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
    height: 40%;
    width: 90%;
    background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .right {
    border-radius: 1rem;
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right .right-area {
    display: flex;
    flex-direction: column;
    align-items: start;

    width: 90%;
    height: 100%;
  }

  .left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 1rem;
  }

  .left .left-area {
    display: flex;
    flex-direction: column;
    align-items: start;

    width: 90%;
    height: 100%;
  }





}









/* MEDIA SIZING */



/* sizing image robot*/
@media screen and (max-width: 654px) {

  .cat_robot {
    display: flex;
    height: 29%;
    width: 100%;
    justify-content: center;
    align-items: end;
  }

  .cat_robot img {
    width: 170px;
    position: relative;
    animation: floating 3s ease-in-out infinite;
  }
}


/* sizing petite image pate*/
@media screen and (max-width: 1300px) {

  .s {
    position: absolute;
    right: 160px;
  }

}

@media screen and (max-width: 1140px) {

  .s {
    position: absolute;
    right: 120px;
  }

}

@media screen and (max-width: 1020px) {

  .s {
    position: absolute;
    right: 90px;
  }

}

@media screen and (max-width: 990px) {

  .s {
    position: absolute;
    right: 60px;
  }

}

@media screen and (max-width: 900px) {

  .s {
    position: absolute;
    right: 20px;
  }

}

@media screen and (max-width: 820px) {

  .s {
    position: absolute;
    right: 10px;
  }

  .s img {
    width: 65px;
    filter: blur(0.2px);

  }
}

@media screen and (max-width: 775px) {

  .s {
    position: absolute;
    right: 25px;
    display: none;
    top: 10px;
  }

  .s img {
    width: 60px;
    filter: blur(0.2px);

  }
}


/* Sizing */
@media screen and (max-width: 596px) {

  .below h2 {
    font-size: clamp(2rem, 2.2rem + 1vw, 2.2rem);
  }

}



@media screen and (max-width: 550px) {

  .s {
    display: none;
  }

  .title span {
    width: 190px;
    font-size: clamp(2rem, 3.2rem + 1vw, 2.3rem);
  }
}

/* HEADER pages a sizing*/

@media screen and (max-width:1550px) {

  header .pages a {
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    cursor: pointer;
    padding: 0.7em 0.6em;
    border-radius: 9px;
  }

}

@media screen and (max-width:1210px) {

  header .pages a {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    cursor: pointer;
    padding: 0.7em 0.6em;
    border-radius: 9px;
  }

}

@media screen and (max-width:1101px) {

  header .pages a {
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    cursor: pointer;
    padding: 0.7em 0.6em;
    border-radius: 9px;
  }

}



@media screen and (max-width: 1008px) {
  header .pages {

    display: none;
    width: 40%;
    height: 90%;
    gap: 1.8vmin;
  }

  header {
    width: 95%;
    max-width: 1900px;
    height: 125px;
    justify-content: space-between;
  }
}

/* Sizing Working*/

@media screen and (max-width:990px) {
  .working {
    display: flex;
    max-width: 1100px;
    width: 80%;
    height: 20%;
    gap: 2vmin;
  }

  .loop img {
    width: 135px;
  }

  .loop {
    right: -50px;
  }
}

@media screen and (max-width:620px) {

  .loop img {
    width: 105px;
  }

  .loop {
    right: -40px;
    top: 35%;
  }
}




/* IPHONE XR */

@media screen and (max-width: 426px) {

  .right .right-area .token-name h2 {
    font-size: 1.575rem;
  }

  .right .right-area .supply h5 {
    font-size: 1em;
  }

  .step {
    position: relative;
    color: white;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    font-size: 1rem;
    display: flex;
    height: 2.6rem;
    width: 2.6rem;
    border: 2px solid #90dbe682;
  }


  .how-it-works {
    width: 85%;
    height: 5%;
    min-height: 140px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 1.7vmin;
    align-items: center;
    color: white;
  }

  .how-it-works .code-background {
    background-color: #070000;
    background-image: linear-gradient(#222 1px, transparent 1px),
      linear-gradient(to right, #222 1px, #1a1a1a 1px);
    background-size: 40px 40px;
    padding: 0.1em 0.6em 0.1em 0.6em;
    color: #82c19e;
    border-radius: 11px;
    z-index: 99;
  }

  .checks {
    display: flex;
    width: 100%;
    height: 55%;
    flex-direction: column;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.135);
  }


  .bands {

    width: 100%;
    display: flex;
    position: relative;
    justify-content: start;
    height: 8%;

  }

  .socials p {
    font-size: 0.8em;
  }



  .first-check {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .double-check {
    width: 100%;
    margin-top: 40px;
    height: 3%;
    display: flex;
    align-items: center;
  }

  .distribution .number h4 {
    font-size: 1em;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
  }



  .below h2 {
    font-size: clamp(2.2rem, 2.2rem + 1vw, 2.5rem);
  }

  .s {
    display: none;
  }

  .cat_robot {
    display: flex;
    height: 29%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .cat_robot img {
    width: 170px;
  }

  .glow h2 {
    font-size: clamp(2rem, 3.2rem + 1vw, 2.7rem);
  }

  .title {
    height: 100%;
  }

  .title span {
    width: 160px;
    font-size: 1.4em;
  }

  .below h2 {
    font-size: 1.4em;
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .title span {
    font-weight: 600;
    width: 110px;
  }


  .glow p {
    color: hsla(0, 0%, 100%, .7);
    white-space: pre-line;
    font-size: clamp(1rem, 0.6rem + 1vw, 1.4rem);
    height: 70%;
    line-height: 2;
    width: auto;
  }

  .small-white {
    color: white;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 0.69em;
  }


  .small-transparent-white {
    color: hsla(0, 0%, 100%, 0.641);
    font-size: 0.7em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  }


  .left .vesting h4 {
    color: hsla(0, 0%, 100%, 0.641);
    -webkit-background-clip: text;
    /* Apply gradient as text color for webkit-based browsers */
    font-size: 0.8em;
  }

  .check-transparent-white {
    color: hsla(0, 0%, 100%, 0.641);
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
  }


  .double-check {
    width: 100%;
    height: 18%;
    display: flex;
    align-items: center;
  }



  .tokenomics {
    border-radius: 1rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
    height: 50%;
    width: 90%;
    background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .glow {
    position: relative;
    width: 88%;
    height: 60vw;
    max-height: 300px;
    max-width: 1300px;
    text-align: center;
    flex-direction: column;
    color: #f7f8f8;
    display: flex;
    justify-content: center;
    gap: 8vmin;
    align-items: center;

  }

  main {
    top: 190px;
    overflow-x: hidden;
  }



  .scroll {
    width: 140%;
    display: flex;
    height: 3%;
    max-height: 110px;
    overflow: hidden;
    justify-content: center;
  }

  .scroll div {
    color: #e7d323;
    font-size: 1.3em;
    white-space: nowrap;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    width: fit-content;
    gap: 4vmin;
    transform: translatmargeX(100%);

  }

  .second-row H3 {
    font-size: 1.2rem;
  }

  .first-row p {

    font-size: 0.8rem;
  }

  .second-row p {
    color: rgba(255, 255, 255, 0.695);

    font-size: 0.8rem;
  }


  .loop img {
    width: 70px;
  }

  .loop {
    right: -30px;
    top: 40%;
  }

  .foug {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    bottom: 0;
  }



}






















/* iphone SE */



@media screen and (max-width: 393px) {

  .right .right-area .token-name h2 {
    font-size: 1.575rem;
  }

  .right .right-area .supply h5 {
    font-size: 1em;
  }

  .step {
    position: relative;
    color: white;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    font-size: 1rem;
    display: flex;
    height: 2.6rem;
    width: 2.6rem;
    border: 2px solid #90dbe682;
  }

  .cat_robot{
    display: none;
  }

  .how-it-works {
    width: 85%;
    height: 10%;
    min-height: 140px;
    max-height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2vmin;
    align-items: center;
    color: white;
  }

  .how-it-works h2{
    font-size: 3.2em;
  }

  .how-it-works .code-background {
    background-color: #070000;
    background-image: linear-gradient(#222 1px, transparent 1px),
      linear-gradient(to right, #222 1px, #1a1a1a 1px);
    background-size: 40px 40px;
    padding: 0.1em 0.6em 0.1em 0.6em;
    color: #82c19e;
    border-radius: 11px;
    z-index: 99;
  }

  .checks {
    display: flex;
    width: 100%;
    height: 55%;
    flex-direction: column;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.135);
  }


  .bands {

    width: 100%;
    display: flex;
    position: relative;
    justify-content: start;
    height: 5%;

  }


  .socials p {
    font-size: 0.8em;
  }



  .first-check {
    width: 100%;
    height: 28%;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .double-check {
    width: 100%;
    margin-top: 40px;
    height: 3%;
    display: flex;
    align-items: center;
  }

  .distribution .number h4 {
    font-size: 1em;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
  }



  .below h2 {
    font-size: clamp(2.2rem, 2.2rem + 1vw, 2.5rem);
  }

  .s {
    display: none;
  }

  .cat_robot {
    display: none;
    height: 29%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .cat_robot img {
    width: 170px;
  }

  .glow h2 {
    font-size: clamp(2rem, 3.2rem + 1vw, 2.7rem);
  }

  .title {
    height: 100%;
  }

  .title span {
    width: 160px;
    font-size: 1.4em;
  }

  .below h2 {
    font-size: 1.4em;
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .title span {
    font-weight: 600;
    width: 110px;
  }


  .glow p {
    color: hsla(0, 0%, 100%, .7);
    white-space: pre-line;
    font-size: clamp(1rem, 0.6rem + 1vw, 1.4rem);
    height: 70%;
    line-height: 2;
    width: auto;
  }

  .small-white {
    color: white;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 0.69em;
  }


  .small-transparent-white {
    color: hsla(0, 0%, 100%, 0.641);
    font-size: 0.7em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  }


  .left .vesting h4 {
    color: hsla(0, 0%, 100%, 0.641);
    -webkit-background-clip: text;
    /* Apply gradient as text color for webkit-based browsers */
    font-size: 0.8em;
  }

  .check-transparent-white {
    color: hsla(0, 0%, 100%, 0.641);
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
  }


  .double-check {
    width: 100%;
    height: 18%;
    display: flex;
    align-items: center;
  }



  .tokenomics {
    border-radius: 1rem;
    --tw-backdrop-blur: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    box-shadow: inset -8px -8px 250px hsla(0, 0%, 100%, .02), inset 0 0 1px 1px hsla(0, 0%, 100%, .04);
    height: 40%;
    width: 90%;
    min-height: 1000px;
    background: radial-gradient(100% 100% at 0 0, hsla(0, 0%, 100%, 0.067) 0, hsla(0, 0%, 100%, .01) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .glow {
    position: relative;
    width: 88%;
    height: 60vw;
    max-height: 300px;
    max-width: 1300px;
    text-align: center;
    flex-direction: column;
    color: #f7f8f8;
    display: flex;
    justify-content: center;
    gap: 8vmin;
    align-items: center;

  }

  .right .right-area .supply h5 {
    font-size: 0.9em;
  
  }

  main {
    top: 190px;
    overflow-x: hidden;
  }



  .scroll {
    width: 140%;
    display: flex;
    height: 3%;
    max-height: 110px;
    overflow: hidden;
    justify-content: center;
  }

  .scroll div {
    color: #e7d323;
    font-size: 1.3em;
    white-space: nowrap;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    width: fit-content;
    gap: 4vmin;
    transform: translatmargeX(100%);

  }

  .second-row H3 {
    font-size: 1.2rem;
  }

  .first-row p {

    font-size: 0.8rem;
  }

  .second-row p {
    color: rgba(255, 255, 255, 0.695);

    font-size: 0.8rem;
  }


  .loop img {
    width: 70px;
  }

  .loop {
    right: -30px;
    top: 40%;
  }

  .foug {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    bottom: 0;
  }

  .bold-white {
    color: white;
    font-size: 0.9em;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
  }
  
  .semi-transparent-white {
    color: hsla(0, 0%, 100%, 0.641);
    font-size: 0.9em;

    font-family: 'Poppins', sans-serif;
  }

  .distribution {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 22%
  }


}


